define(
// @formatter:off
    'partials/models/FormModel',[
        'jquery',
        'mmc.libs.log',
        'backbone'
    ], 
    function(
        $,
        log,
        Backbone
    ) {
// @formatter:on
    var FormModel = Backbone.Model.extend({       

        url : '',

        initialize : function(options) {
            this.url = options.url;
        },

        sendRequest : function($form, successCallback, errorCallback) {
            $form.ajaxSubmit({
                url : this.url,
                type : 'POST',
                dataType : 'json',
                data : {
                    campaign : ''
                },
                cache : false,
                success : function(data) {
                    if (data.success === true) {
                        successCallback(data);
                    } else {
                        errorCallback(data.errorMsg);
                    }
                },
                error : errorCallback
            });
        }

    });

    return FormModel;
});

