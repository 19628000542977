// UMD PATTERN
( function(root, factory) {
        if ( typeof define === 'function' && define.amd) {
            define('jquery-private',['jquery'], factory);
        } else if ( typeof exports === 'object') {
            module.exports = factory(require('jquery'));
        }
    }(this, function($) {
        // END UMD-PATTERN

        "use strict";

        var jQueryPrivate = $.noConflict(true);
        return jQueryPrivate;

    })
);

