define(
//@formatter:off
	'partials/views/abstract/AbstractView',[
	   'backbone',
	   'underscore',
	   'mmc.libs.log'
	],
	function(
	    Backbone,
	    _,
	    log
	) {
//@formatter:on
    var AbstractView = Backbone.View.extend({

        /*
        * PUBLIC FUNCTIONS
        */

        /**
         * create view and binds all function to the new object
         *
         * @return undefined
         */
        initialize : function(options) {
            _.bindAll.apply(_, [this].concat(_.functions(this)));

            // init hooks
            _.extend(this.hooks, options.hooks);
            for (func in this.hooks) {
                this.hooks[func] = _.bind(this.hooks[func], this);
            }
        },

        hooks : {

        },

        /**
         * toggle loading class
         *
         * @return
         */
        toggleLoading : function() {
            this.$el.toggleClass('is-loading');
        },

        /**
         * unbinds all event on this object and removes it from the DOM
         *
         * @return
         */
        close : function() {
            this.undelegateEvents(), this.$el.removeData().unbind();
            this.remove();

            Backbone.View.prototype.remove.call(this);
        }
    });

    return AbstractView;
});

