define(
// @formatter:off
    'partials/views/FormView',[
        'jquery',
        'mmc.libs.log',
        'mmc.libs.Get',        
        'fastclick',
        'partials/views/abstract/AbstractPageView',
        'mmc.libs.formValidation',
        'jquery.form',
        'mmc.libs.jquery.mmcFormDefaults',
        'mmc.libs.jquery.mmcFileUploader'
    ], 
    function(
        $,
        log,
        Get,
        FastClick,
        AbstractPageView,
        formValidation
    ) {
// @formatter:on
    var FormView = AbstractPageView.extend({

        DOM : null,

        /*
         * PUBLIC FUNCTIONS
         */

        initialize : function(options) {
            AbstractPageView.prototype.initialize.call(this, options);
        },

        render : function() {

            this.loadFormModelFromCache();

            AbstractPageView.prototype.render.call(this);
            this.DOM = {
                $form : this.$el.find('form'),
                $birthday : this.$el.find('.birthday'),
                $submitButton : this.$el.find('button[type="submit"]'),
                $errorWrap : this.$el.find('.error-wrap p')
            };
            
            this.$el.find('[placeholder]').mmcFormDefaults();
            this.$el.find('.file-uploader').mmcFileUploader();

            this.$el.find('.terms_toggle').on('click', function(e) {
                e.preventDefault();
                MMCLIBS.appRouter.navigate('terms', {
                    trigger : true
                });
            });

            this.$el.find('form').on('submit', this.onSubmit);

            this.refillForm();
            this.DOM.$form.on('change', this.cacheFormModel);

            FastClick.attach(this.$el[0]);
            
            setTimeout( function() {
                var get = new Get();
                var autoSubmit = get.param('autoSubmit');
                if (autoSubmit === '1' && !this.autoSubmitted) {
                    this.autoSubmitted = true;
                    this.$el.find('form').submit();
                }
            }.bind(this), 30);            
        },
        
        remove : function() {
            AbstractPageView.prototype.remove.call(this);
            this.DOM = null;
        },

        cacheFormModel : function() {
            var serializedData = this.DOM.$form.serializeArray();
            
            $.each(serializedData, function(key, field){
                this.model.set(field.name, field.value);
            }.bind(this));

            
        },

        loadFormModelFromCache : function() {
            // TODO: optional: check if local storage exists in browser and load from local storage
        },

        refillForm : function() {

            $.each(this.model.attributes, function(name, val){

                var $el = this.$el.find('[name="' + name + '"]'),
                    type = $el.attr('type');

                switch(type){
                    case 'checkbox':
                        $el.prop('checked', true);
                        break;
                    case 'radio':
                        $el.filter('[value="' + val + '"]').prop('checked', true);
                        break;
                    default:
                        $el.val(val);
                }
            }.bind(this));

        },
    
        hide : function() {
            this.cacheFormModel();
            AbstractPageView.prototype.hide.call(this);
            this.trigger('hide');
        },
        

        /*
         * HOOKS
         */

        hooks : {
            onSubmit : function($formWrap) {
                try {
                    this.trigger('onSubmit', $formWrap);
                    return true;
                } catch(e) {
                    if ( typeof e === 'string') {
                        this.setErrorMessage(e);
                    } else {
                        log(e);
                    }
                    return false;
                }
            },

            onBeforeSend : function($formWrap, formModel) {
                try {
                    this.trigger('onBeforeSend', $formWrap, formModel);
                    return true;
                } catch(e) {
                    if ( typeof e === 'string') {
                        this.setErrorMessage(e);
                    } else {
                        log(e);
                    }
                    return false;
                }
            },

            onSuccessSend : function(data) {
                this.DOM.$submitButton.prop('disabled', false);
                this.$el.removeClass('is-loading');
                this.trigger('onSuccessSend', data);
                MMCLIBS.appRouter.navigate('thanks', {
                    trigger : true
                });
            },

            onErrorSend : function(error) {
                if ( typeof error === 'string') {
                    this.setErrorMessage(error);
                } else {
                    this.setErrorMessage(this.$el.find('.error-wrap').data('errormessage'));
                }
                this.DOM.$submitButton.prop('disabled', false);
                this.$el.removeClass('is-loading');
                this.trigger('onErrorSend', error);
            }
        },

        /*
         * PRIVATE FUNCTIONS - EVENT HANDLERS
         */

        onSubmit : function(e) {
            e.preventDefault();
            this.clearErrorMessage();

            if (!this.hooks.onSubmit(this.$el)) {
                return;
            }

            if (!this.validateForm()) {
                return;
            }

            if (!this.hooks.onBeforeSend(this.$el, this.model)) {
                return;
            }

            this.DOM.$submitButton.prop('disabled', true);
            this.$el.addClass('is-loading');
            this.model.sendRequest(this.$el.find('form'), this.hooks.onSuccessSend, this.hooks.onErrorSend);
        },

        /*
         * PRIVATE FUNCTIONS
         */

        validateForm : function() {
            var validationErrors = formValidation.validateForm(this.DOM.$form);

            if (validationErrors.length > 0) {
                this.setErrorMessage(validationErrors[0].attr('data-errormessage'));
                return false;
            }

            // VALIDATE BIRTHDAY
            $geb_year = this.DOM.$form.find('select[name="birth_year"]');
            $geb_month = this.DOM.$form.find('select[name="birth_month"]');
            $geb_day = this.DOM.$form.find('select[name="birth_day"]');
            if ($geb_year.length > 0 && $geb_month.length > 0 && $geb_day.length > 0 && !this.validateBirthDay($geb_year, $geb_month, $geb_day)) {
                return false;
            }

            return true;
        },

        validateBirthDay : function($geb_year, $geb_month, $geb_day) {
            if (!formValidation.validateDate($geb_year.val(), $geb_month.val(), $geb_day.val())) {
                $geb_day.closest('.form-field').addClass('error');
                $geb_month.closest('.form-field').addClass('error');
                $geb_year.closest('.form-field').addClass('error');
                this.setErrorMessage(this.DOM.$birthday.attr('data-errormessage'));
                return false;
            }

            // MINIMUM AGE
            var userAge = formValidation.getAgeByYearMonthDay($geb_year.val(), $geb_month.val(), $geb_day.val()),
                minAge = this.DOM.$birthday.attr('data-minage');
            if (minAge > 0 && userAge < minAge) {
                $geb_day.closest('.form-field').addClass('error');
                $geb_month.closest('.form-field').addClass('error');
                $geb_year.closest('.form-field').addClass('error');
                this.setErrorMessage(this.DOM.$birthday.attr('data-errorage'));
                return false;
            }

            return true;
        },

        setErrorMessage : function(msg) {
            this.DOM.$errorWrap.text(msg);
        },

        clearErrorMessage : function() {
            this.DOM.$errorWrap.html('&nbsp;');
        }
    });

    return FormView;
});

