define(
// @formatter:off
    'partials/views/abstract/AbstractPageView',[
        'jquery',
        'underscore',
        'mmc.libs.log',
        'partials/views/abstract/AbstractView'
    ], 
    function(
        $,
        _,
        log,
        AbstractView
    ) {
// @formatter:on
    var AbstractPageView = AbstractView.extend({

        _template : null,

        /*
         * PUBLIC VARS - READ ONLY
         */

        name : null,
        rendered : false,

        /*
         * PUBLIC FUNCTIONS
         */

        initialize : function(options) {
            this.name = options.name;
            this._template = _.template(options.templateString);
            AbstractView.prototype.initialize.call(this, options);
        },

        render : function() {
            this.el = (this.$el = $(this._template((this.model || {}).attributes)))[0];
            this.rendered = true;
        },

        /**
         * called by AppRouter when navigated to the view
         *
         * @return
         */
        show : function() {
            this.$el.fadeIn();
            this.trigger('show');
        },

        /**
         * called by AppRouter to hide this page
         *
         * @return
         */
        hide : function() {
            this.$el.hide();
            this.trigger('hide');
        },

        /**
         * called by AppRouter to remove this page from DOM
         *
         * @return
         */
        remove : function() {
            if (!this.rendered) {
                return;
            }
            this.hide();
            this.$el.removeData().unbind();
            this.$el.remove();
            this.el = undefined;
            this.$el = undefined;
            this.rendered = false;
        }
    });

    return AbstractPageView;
});

