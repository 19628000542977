require.config({
    paths : {
        urlArgs : 'noCache=' + (new Date()).getTime(),

        // mmc libs
        'mmc.libs.log' : '../../vendor/log/dist/log.min',
        'mmc.libs.namespace' : '../../vendor/namespace/dist/namespace.min',
        'mmc.libs.trackingHelper' : '../../vendor/TrackingHelper/dist/TrackingHelper.min',
        'mmc.libs.formValidation' : '../../vendor/FormValidation/dist/FormValidation.min',
        'mmc.libs.documentDomain' : '../../vendor/documentDomain/dist/documentDomain.min',
        'mmc.libs.iFrameResizer' : '../../vendor/iFrameResizer/dist/iFrameResizer.min',
        'mmc.libs.jquery.mmcFormDefaults' : '../../vendor/mmcFormDefaults/dist/mmcFormDefaults.min',
        'mmc.libs.jquery.mmcFileUploader' : '../../vendor/mmcFileUploader/dist/mmcFileUploader.min',
        'mmc.libs.ShareIconsModule' : '../../vendor/ShareModule/dist/ShareModule.min',
        'mmc.libs.Get' : '../../vendor/Get/dist/Get.min',
        'mmc.libs.booleanExpressionParser' : '../../vendor/booleanExpressionParser/dist/booleanExpressionParser.min',

        // libs
        jquery : '../../vendor/jquery/dist/jquery.min',
        'jquery-private' : '../../vendor/jquery-private/dist/jquery-private',
        'jquery.form' : '../../vendor/jquery-form/jquery.form',
        'jquery.cycle2' : '../../vendor/jquery.cycle2.min/build/jquery.cycle2.min',
        'jquery.isinview': '../../vendor/jquery.isinview/dist/jquery.isinview.min',
        underscore : '../../vendor/underscore/underscore',
        backbone : '../../vendor/backbone/backbone',
        fastclick : '../../vendor/fastclick/lib/fastclick',
        bootstrap : '../../vendor/bootstrap-sass/assets/javascripts/bootstrap.min'
    },

    shim : {
        'jquery.cycle2' : {
            deps : ['jquery']
        }
    },

    map : {
        // '*' means all modules will get 'jquery-private'
        // for their 'jquery' dependency.
        '*' : {'jquery' : 'jquery-private'},

        // 'jquery-private' wants the real jQuery module
        // though. If this line was not here, there would
        // be an unresolvable cyclic dependency.
        'jquery-private' : {'jquery' : 'jquery'}
    }

});

define("default/config", function(){});

