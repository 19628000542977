define(
// @formatter:off
    'partials/router/AppRouter',[
        'jquery',
        'mmc.libs.log',
        'backbone'
    ], 
    function(
        $,
        log,
        Backbone
    ) {
// @formatter:on
    var AppRouter = Backbone.Router.extend({

        /****************
         * PRIVATE VARS *
         ****************/

        defaultView : null,
        views : null,

        currentView : null,

        routes : {
            // IMPORTANT - ORDER OF MATCHING ROUTES IS IMPORTANT !!! (GREEDY ALGORITHM)
            '' : 'pageAction',
            ':page(/)' : 'pageAction'
        },

        /********************
         * PUBLIC FUNCTIONS *
         ********************/

        /**
         * @public
         *
         * constructor method - binds the basic methods to the created object
         * stores given references as private vars
         * sets event listener
         * @param {Object} options
         */
        initialize : function(options) {
            _.bindAll.apply(_, [this].concat(_.functions(this)));

            this.defaultView = options.defaultView;
            this.views = options.views;
        },

        /*********************
         * PRIVATE FUNCTIONS *
         *********************/

        /**
         * @private
         *
         * navigates to a given page using "routerPageAction" event - if no page found triggers navigation to 404 page
         * @param string page
         * @param {Object} filterModel
         */
        pageAction : function(page) {
            var targetView;

            for (var i = 0; i < this.views.length; i++) {
                var view = this.views[i];
                if (view.name === page || (page === null && this.defaultView === view)) {
                    targetView = view;
                } else {
                    view.remove();
                }
            }

            if (targetView === this.currentView) {
                return;
            }

            if (!targetView) {
                targetView = this.defaultView;
            }

            if (!targetView.rendered) {
                this.currentView = targetView;
                targetView.render();
                targetView.hide();
                $('.mmc-stdform').append(targetView.$el);
                MMCLIBS.trackingHelper.initTrackingClickListener();
                targetView.show();
            }
        }
    });

    return AppRouter;
});

