define(
// @formatter:off
    'partials/views/ThxPageView',[
        'jquery',
        'mmc.libs.log',
        'fastclick',
        'partials/views/abstract/AbstractPageView',
        'mmc.libs.ShareIconsModule'
    ], 
    function(
        $,
        log,
        FastClick,
        AbstractPageView,
        ShareIconsModule
    ) {
// @formatter:on
    var ThxPageView = AbstractPageView.extend({

        initialize : function(options) {
            AbstractPageView.prototype.initialize.call(this, options);
        },

        render : function() {
            AbstractPageView.prototype.render.call(this);
            FastClick.attach(this.$el[0]);
            if (this.$el.find('.share-icons-module').length > 0) {
                var shareModule = new ShareIconsModule({
                    $container : this.$el.find('.share-icons-module')
                });
            }
        },

        show : function() {
            MMCLIBS.trackingHelper.track('event', 'form-thx-page');
            this.$el.hide().fadeIn(400, function() {
                // show FB like-box
                if ( typeof FB === 'object') {
                    var $fbLikeBox = this.$el.find('.thx-page-fb-like-box');
                    FB.XFBML.parse();
                }

                // show youtube-teaser
                if ( typeof gapi === 'object') {
                    var $youtubeTeaser = this.$el.find('.thx-page-teaser-youtube');
                    gapi.ytsubscribe.go($youtubeTeaser[0]);
                }
            }.bind(this));
            this.trigger('show');
        }
    });

    return ThxPageView;
});

