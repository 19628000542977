require.config({
    baseUrl : (( typeof JSConst === 'object') ? JSConst.config.absRefPrefix : '/') + 'src/js'
});
require(['default/config'], function() {
    // @formatter:off
    require([
        'jquery',
        'mmc.libs.log',
        'mmc.libs.namespace',
        'mmc.libs.trackingHelper',
        'backbone',
        'partials/models/FormModel',
        'partials/views/FormView',
        'partials/views/TermsView',
        'partials/views/ThxPageView',
        'partials/router/AppRouter',
        'mmc.libs.documentDomain',
        'mmc.libs.iFrameResizer',
        'jquery.isinview',
        'bootstrap'
    ], function(
        $,
        log,
        namespace,
        TrackingHelper,
        Backbone,
        FormModel,
        FormView,
        TermsView,
        ThxPageView,
        AppRouter
    ) {
    // @formatter:on

        var trackingHelper,
            formModel,
            formView,
            termsView,
            thxPageView,
            appRouter;

        /*
         * init trackinghelper
         */
        (function() {
            var pageName;
            try {
                pageName = parent.dataLayer[0].pageNameVirtual;
            } catch(e) {
                pageName = '';
            }
            var trackingHelperConfig = {
                clean : true,

                pageName : ((JSConst.config.tracking.gtm.pageName.length > 0) ? JSConst.config.tracking.gtm.pageName : pageName),
                trackingCategory : 'Campaigns',
                trackingAction : JSConst.config.tracking.gtm.action,

                // To deactivate tracking for a specific tracking-engine set the function : null
                trackingMethods : {
                    trackGAJS : null,
                    trackAnalyticsJS : null,
                    trackOmniture : null
                    // trackGenericGTM : null
                }
            };

            trackingHelper = new TrackingHelper(trackingHelperConfig);
        })();

        /*
         * init backbone app
         */
        (function() {
            if ($('.mmc-stdform .template--form').length < 1) {
              return;
            }

            formModel = new FormModel({
                url : window.JSConst.config.absRefPrefix + 'participation/'
            });

            formView = new FormView({
                name : 'form',
                templateString : $('.mmc-stdform .template--form').html(),
                model : formModel
            });
            formView.on('onSubmit', function($formWrap) {
            });
            formView.on('onBeforeSend', function($formWrap, sendFieldsObj) {
                // to prevent form submission (e.g. after custom validation) throw string (which will be shown as error message):
                // throw 'custom validation error message';
                if (sendFieldsObj['newsletter'] === '1') {
                    trackingHelper.track({
                        type : 'trackNOPV',
                        name : 'newsletter_checked'
                    });
                }
                if (sendFieldsObj['club'] === '1') {
                    trackingHelper.track({
                        type : 'trackNOPV',
                        name : 'club_checked'
                    });
                }
            });

            termsView = new TermsView({
                name : 'terms',
                templateString : $('.mmc-stdform .template--terms').html()
            });

            thxPageView = new ThxPageView({
                name : 'thanks',
                templateString : $('.mmc-stdform .template--thx-page').html()
            });

            thxPageView.on('show', function(){
                trackingHelper.track('pageview', 'thanks');
            });


            appRouter = new AppRouter({
                defaultView : formView,
                views : [formView, termsView, thxPageView]
            });

            /*
             * create namespace and expose objects
             */
            namespace('MMCLIBS');
            MMCLIBS.trackingHelper = trackingHelper;
            MMCLIBS.formModel = formModel;
            MMCLIBS.formView = formView;
            MMCLIBS.termsView = termsView;
            MMCLIBS.thxPageView = thxPageView;
            MMCLIBS.appRouter = appRouter;

            /*
             * start routing
             */
            Backbone.history.start();
        })();



        // impressum-acc
        $('.collapse').collapse({
            toggle: false
        });
        $('.collapse').on('shown.bs.collapse', function () {
            var id = $(this).attr('id');
            $('html, body').animate({
                scrollTop: $("#"+id).offset().top
            }, 1000);
        })


        //$(window).on('scroll', function() {
            //console.log($('.viewport-helper').isInViewport());
        //});

        var velocity = 0.2;
        function update(){
            var pos = $(window).scrollTop();
            $('.form').each(function() {
                var $element = $(this);
                // subtract some from the height b/c of the padding
                var height = $element.height()-18;
                $(this).css('backgroundPosition', '50% ' + Math.round((height - pos) * velocity) + 'px');
            });
        };

        $(window).on('scroll', function() {
            if($('.viewport-helper').isInViewport()){
                console.log('.mmc-promo.isInViewport');
                $(window).bind('scroll', update);
            }
        });

    });
});

define("default/master", function(){});

