define(
// @formatter:off
    'partials/views/TermsView',[
        'jquery',
        'mmc.libs.log',
        'fastclick',
        'partials/views/abstract/AbstractPageView',
        'backbone'
    ], 
    function(
        $,
        log,
        FastClick,
        AbstractPageView,
        Backbone
    ) {
// @formatter:on
    var TermsView = AbstractPageView.extend({

        initialize : function(options) {
            AbstractPageView.prototype.initialize.call(this, options);
        },

        render : function() {
            AbstractPageView.prototype.render.call(this);
            this.$el.find('.btn-close').on('click', function(e) {
                e.preventDefault();
                Backbone.history.history.back();
            });

            FastClick.attach(this.$el[0]);
        }
    });

    return TermsView;
});

